/**
* DO NOT USE THIS FOR COMPONENT STYLING
*/
body.loading {
  $green: #008744;
  $blue: #0057e7;
  $red: #d62d20;
  $yellow: #ffa700;
  $white: #eee;
  $black: #000;
  
  // scaling... any units
  $width: 100px;

  background-color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .circular {
    animation: body-loader-rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: body-loader-dash 1.5s ease-in-out infinite, body-loader-color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes body-loader-rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes body-loader-dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes body-loader-color {
    100%,
    0% {
      stroke: $red;
    }
    40% {
      stroke: $blue;
    }
    66% {
      stroke: $green;
    }
    80%,
    90% {
      stroke: $yellow;
    }
  }  
}