/**
* DO NOT USE THIS FOR COMPONENT STYLING
*/
@import "./baseline.scss";
@import "./loader.scss";

html {
  min-height: 100vh;
}

body {
  display: flex;
  height: 100vh;
  // overflow: hidden;
  flex-direction: column;
}

#root {
  display: flex;
  min-height: 100vh;
  flex: 16px 1;
  flex-direction: column;
}

#error {
  padding: 20px;
}

.markdown-page {
  h1, h2, h3, h4, h5, h6 { 
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 16px;
    margin-top: 24px;
  }
  
  h1{
    font-size: 2em;
    border-bottom: 1px solid #eaecef;
    padding-bottom: .3em;
  }
  
  h2 {
    font-size: 1.5em;
    border-bottom: 1px solid #eaecef;
    padding-bottom: .3em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: .875em;
  }

  h6 {
    font-size: .85em;
    color: #6a737d;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
}

.error-message p {
  margin: 0;
}

.error-message a {
  color: white;
  text-decoration: underline;

  &:hover { color: darken(white, 10); }
}

