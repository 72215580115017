/**
* DO NOT USE THIS FOR COMPONENT STYLING
*/
$font-family-serif: "Roboto Slab", "Noto Serif", serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-sans-serif: "Noto Sans", Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: "Fira Code", Inconsolata, Consolas, SFMono-Regular, Menlo, Monaco, "Liberation Mono", "Courier New", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base: $font-family-sans-serif !default;

@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_mixins";
// @import url(/node_modules/bootstrap/scss/_root);

:root {
  // Use `inspect` for lists so that quoted items keep the quotes.
  --font-family-sans-serif: #{inspect($font-family-sans-serif)};
  --font-family-monospace: #{inspect($font-family-monospace)};
}


@import "bootstrap/scss/_reboot";

// revert bootstrap reset behavior
button:focus {
  outline: none;
}

// Contrast over 4.5
a {
  color: #026fe3;
}

// for legacy-browser or error pages
body.message,
body.error {
  display: block !important;
  height: 100vh !important;
  overflow: auto !important;
  padding: 2rem !important;
  font-family: #{inspect($font-family-sans-serif)};
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: #{inspect($font-family-serif)};
  font-size: 1.50rem;
}

.md { 
  h1 { font-size: 1.8rem; font-weight: bold; }
  h2 { font-size: 1.5rem; font-weight: bold; }
  h3 { font-size: 1.2rem; font-weight: bold; }
  }
